<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model="searchText"
        placeholder="ชื่อสินค้า หรือ รหัสสินค้า"
        color="secondary"
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        @click:prepend-inner="onSearch()"
        @keyup.enter="onSearch()" />
    </v-col>
    <v-col
      v-if="!fetchingProduct"
      cols="12">
      <v-row class="product-search-list">
        <v-col
          v-for="(product, index) in products"
          :key="`product-list-${index}`"
          cols="12">
          <v-row>
            <v-col cols="9">
              <h4>
                {{ avoidNullValue(product, 'code') }}
              </h4>
              <p class="mb-0 text-body-2">
                {{ avoidNullValue(product, 'name') }}
              </p>
              <p class="mb-0 text-body-2 text-uppercase">
                {{ `${avoidNullValue(product, 'size')} - ${avoidNullValue(product, 'color')}` }} : {{ product.price | showFullPriceFormat() }}
              </p>
              <p class="mb-0 text-body-2">
                Collection : {{ product.collection }}
              </p>
            </v-col>
            <v-col cols="3">
              <v-btn
                small
                color="primary"
                @click="addProduct(product)">
                เพิ่ม
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="py-0">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-else
      cols="12">
      <v-row
        class="product-search-list">
        <v-col
          cols="12"
          class="d-flex justify-center align-center">
          <div class="loading">
            <span class="loading-dots">•</span>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      products: {
        type: Array,
        default: () => []
      },
      fetchingProduct: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        searchText: ''
      }
    },
    methods: {
      onSearch () {
        this.$emit('search', this.searchText)
      },
      avoidNullValue (object, properties, defaultValue = '-') {
        return object[properties] || defaultValue
      },
      addProduct (product) {
        this.$emit('add-product', product)
      }
    }
  }
</script>

<style>
.number-field.v-input input {
  text-align: center;
}

</style>

<style scoped>
.product-search-list {
  height: calc(100vh - 190px);
  overflow-y: scroll;
}

.loading {
  margin: 3em 0;
}

.loading-dots {
  text-align: center;
  font-size: 2em;
  color: rgba(0,0,0,0);
  animation-name: loading-dots-animation;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loading-dots-animation {
  0% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.2);
  }
  20% {
    text-shadow: -1em 0 0 rgba(0,0,0,1), 0em 0 0 rgba(0,0,0,.6), 1em 0 0 rgba(0,0,0,.2);
  }
  40% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,1), 1em 0 0 rgba(0,0,0,0.6);
  }
  60% {
    text-shadow: -1em 0 0 rgba(0,0,0,.2), 0em 0 0 rgba(0,0,0,.6), 1em 0 0 rgba(0,0,0,1);
  }
  80% {
    text-shadow: -1em 0 0 rgba(0,0,0,.2), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.6);
  }
  100% {
    text-shadow: -1em 0 0 rgba(0,0,0,.6), 0em 0 0 rgba(0,0,0,.2), 1em 0 0 rgba(0,0,0,.2);
  }
}

.loading-text {
  font-weight: bold;
  color: #000;
  margin: 0.3em 0;
  font-size: 1.5em;
}
</style>
